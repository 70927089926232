import React, { useEffect } from "react";
import TopNavbar from "./../../component/nav/TopNavbar";
import { useState } from "react";
import Footer from "../../component/partials/Footer";
import * as ICONS from "../../assets/icons";
import Contact from "../../component/partials/Contact";

const PrivacyPolicy = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [activeTerm, setActiveTerm] = useState(0);

  const toggleTerm = (term) => {
    setActiveTerm((activeTerm) => {
      return activeTerm == term ? -+term : term;
    });
  };

  useEffect(() => {
    console.log(activeTerm);
  }, [activeTerm]);
  return (
    <div className='h-screen w-screen overflow-scroll'>
      <TopNavbar navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen} />

      {/* Main */}
      <main className='mt-20 md:mt-15  relative'>
        <div className=''>
          <div className='privacy_policy p-4 pt-10 md:w-[75%] md:mx-auto'>
            <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl md:pb-3 md:border-b md:border-b-isPurple300'>
              Our Privacy Policy
            </h2>
            <div className='mt-4 font-inter font-normal text-[15px]'>
              <p>
                <span className='font-semibold'>
                  <i>PayTyme</i>
                </span>{" "}
                ("we," "our," or "us") is committed to protecting your privacy
                This Privacy Policy explains how your personal information is
                collected, used, and disclosed by PayTyme.
              </p>
              <p className='mt-4'>
                This Privacy Policy applies to our application named PayTyme and
                its associated subdomains (collectively, our "Service"). By
                accessing or using our Service, you signify that you have read,
                understood, and agree to our collection, storage, use, and
                disclosure of your personal information as described in this
                Privacy Policy and our Terms of Service.
              </p>
            </div>
          </div>
          <section className='bg-[#F5F5FD] p-4 py-8 mt-10 md:pt-16'>
            <div className='md:w-[75%] md:mx-auto font-inter font-normal text-[15px]'>
              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      Definitions and key terms
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(1)}
                    >
                      {
                        activeTerm == 1  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 1 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      To help explain things as clearly as possible in this
                      Privacy Policy, every time any of these terms are
                      referenced, are strictly defined as:
                    </p>
                  </div>
                </div>
                <div className={`${activeTerm == 1 ? "" : "hidden"}`}>
                  <ul className='pl-6 md:pl-10'>
                    <li className='list-disc'>
                      <span className='font-bold'>Cookie:</span> small amount of
                      data generated by a website and saved by your web browser.
                      It is used to identify your browser provide analytics
                      remember information about vou such as vour language
                      preference or login information.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Company:</span> when this
                      policy mentions "Company," "we," "us," or "our," it refers
                      to PayTyme that is responsible for your information under
                      this Privacy Policy.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Country:</span> where PayTyme
                      or the owners/founders of PayTyme are based, in this case
                      is Nigeria.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Customer:</span> refers to the
                      company, organization or person that signs up to use the
                      PayTyme Service to manage the relationships with your
                      consumers or service users.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Device:</span> any internet
                      connected device such as a phone, tablet, computer or any
                      other device that can be used to visit PayTyme and use the
                      services.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>IP address:</span> Every
                      device connected to the Internet is assigned a number
                      known as an Internet protocol (IP) address. These numbers
                      are usually assigned in geographic blocks. An IP address
                      can often be used to identify the location from which a
                      device is connecting to the Internet.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Personnel:</span> refers to
                      those individuals who are employed by PayTyme or are under
                      contract to perform a service on behalf of one of the
                      parties.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Personal Data:</span> any
                      information that directly, indirectly, or in connection
                      with other information - including a personal
                      identification number - allows for the identification or
                      identifiability of a natural person.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Service:</span> refers to the
                      service provided by PayTyme as described in the relative
                      terms (if available) and on this platform.
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Third-party service:</span>{" "}
                      refers to advertisers, contest sponsors, promotional and
                      marketing partners, and others who provide our content or
                      whose products or services we think may interest you
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>Website:</span> PayTyme's
                      site, which can be accessed via this URL:
                    </li>
                    <li className='list-disc mt-4'>
                      <span className='font-bold'>You:</span> a person or entity
                      that is registered with PayTyme to use the Services.
                    </li>
                  </ul>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    What Information Do We Collect?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(2)}
                    >
                      {
                        activeTerm == 2  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 2 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      We collect information from you when you visit our service, register, place an order, subscribe to our newsletter, respond to a survey or fill out a form.
                    </p>
                  </div>
                </div>
                <div className={`${activeTerm == 2 ? "" : "hidden"}`}>
                  <ul className='pl-6 md:pl-10'>
                    <li className='list-disc pt-1'>
                      Name / Username
                    </li>
                    <li className='list-disc pt-1'>
                      Phone Numbers
                    </li>
                    <li className='list-disc pt-1'>
                      Email Addresses
                    </li>
                    <li className='list-disc pt-1'>
                      Debit/credit card numbers
                    </li>
                    <li className='list-disc pt-1'>
                      Password
                    </li>
                  </ul>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    How Do We Use The Information We Collect?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(3)}
                    >
                      {
                        activeTerm == 3  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 3 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    Any of the information we collect from you may be used in one of the following ways:
                    </p>
                  </div>
                </div>
                <div className={`${activeTerm == 3 ? "" : "hidden"}`}>
                  <ul className='pl-6 md:pl-10'>
                    <li className='list-disc pt-1'>
                      To personalize your experience (your information helps us to better respond to your individual needs)
                    </li>
                    <li className='list-disc pt-1'>
                      To improve our service (we continually strive to improve our service offerings based on the information and feedback we receive from you)
                    </li>
                    <li className='list-disc pt-1'>
                      To improve customer service(your information helps us to more effectively respond to your customer service requests and support needs)
                    </li>
                    <li className='list-disc pt-1'>
                      To administer a contest, promotion, survey or other site feature
                    </li>
                    <li className='list-disc pt-1'>
                      To send periodic emails
                    </li>
                  </ul>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    When do we use customer information from third parties?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(4)}
                    >
                      {
                        activeTerm == 4  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 4 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming our customer, we receive information from a third party that provides automated fraud detection services to us. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Do we share the information we collect with third parties?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(5)}
                    >
                      {
                        activeTerm == 5  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 5 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest
                    </p>
                    <p className="pt-5">We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and our service, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through our service. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.</p>

                    <p className="pt-5">We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the service in a shared location, and type of device used to visit the service. They may aggregate information about our advertising and what you see on the service and then provide auditing. research and reporting for us and our advertisers.</p>

                    <p className="pt-5">We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.</p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      Where and when is information collected from customers and end users?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(6)}
                    >
                      {
                        activeTerm == 6  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 6 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    How Do We Use Your Email Address?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(7)}
                    >
                      {
                        activeTerm == 7  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 7 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    By submitting your email address on our service, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Could my information be transferred to other countries?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(8)}
                    >
                      {
                        activeTerm == 8  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 8 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We are incorporated in Nigeria. Information collected via our website, through direct interactions with you, or from use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law. by using any of the above, you voluntarily consent to the trans border transfer and hosting of such information.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Is the information collected through our service secure?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(9)}
                    >
                      {
                        activeTerm == 9  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 9 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      Can I update or correct my information?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(10)}
                    >
                      {
                        activeTerm == 10  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 10 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    The rights you have to request updates or corrections to the information we collect depend on your relationship with us. Personnel may update or correct their information as detailed in our internal company employment policies. Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.
                    </p>
                    <p className="pt-5">
                    To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times. You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable. If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      Sale of Business
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(11)}
                    >
                      {
                        activeTerm == 11  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 11 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of us or any of its Corporate Affiliates (as defined herein), or that portion of us or any of its Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy. reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      Affiliates
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(12)}
                    >
                      {
                        activeTerm == 12  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 12 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under common control with us, whether by ownership or otherwise. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    How Long Do We Keep Your Information?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(13)}
                    >
                      {
                        activeTerm == 13  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 13 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We keep your information only so long as we need it to provide service to you and fulfill the purposes described in this policy. This is also the case for anyone that we share your information with and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we'll either remove it from our systems or depersonalize it so that we can't identify you.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                      How Do We Protect Your Information?
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(14)}
                    >
                      {
                        activeTerm == 14  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 14 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to us or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Governing Law
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(14)}
                    >
                      {
                        activeTerm == 14  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 14 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    The laws of Nigeria, excluding its conflicts of law rules, shall govern this Agreement and your use of our service. Your use of our service may also be subject to other local, state, national, or international laws.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Your Consent
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(15)}
                    >
                      {
                        activeTerm == 15  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 15 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    The laws of Nigeria, excluding its conflicts of law rules, shall govern this Agreement and your use of our service. Your use of our service may also be subject to other local, state, national, or international laws.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Links to Other Websites
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(16)}
                    >
                      {
                        activeTerm == 16  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 16 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by us. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated. monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website's own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Cookies
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(17)}
                    >
                      {
                        activeTerm == 17  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 17 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      We use "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Kids' Privacy
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(18)}
                    >
                      {
                        activeTerm == 18  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 18 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      We do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from our servers.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Changes To Our Privacy Policy
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(19)}
                    >
                      {
                        activeTerm == 19  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 19 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                    If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Third-Party Services
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(20)}
                    >
                      {
                        activeTerm == 20  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 20 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").
                    </p>
                    <p className="pt-5">
                      You acknowledge and agree that we shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. We do not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
                    </p>
                    <p className="pt-5">
                      Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
                    </p>
                  </div>
                </div>
              </article>

              <article>
                <div className='mb-6 pt-4 border-t border-t-isPurple300'>
                  <div className='flex justify-between items-center'>
                    <h2 className='text-isSkyBlue400 text-lg font-medium md:text-2xl '>
                    Tracking Technologies
                    </h2>
                    <span
                      className='cursor-pointer text-2xl'
                      onClick={() => toggleTerm(21)}
                    >
                      {
                        activeTerm == 21  ? <ICONS.AiOutlineMinus /> : <ICONS.AiOutlinePlus />
                      }
                    </span>
                  </div>
                  <div
                    className={`${
                      activeTerm == 21 ? "" : "hidden"
                    } mt-4 font-inter font-normal text-[15px]`}
                  >
                    <p>
                      <span className="block font-bold mb-2 relative ml-3"> <span className="w-1 h-1 content-none bg-isDark500 absolute -left-2.5 top-2 rounded-full"></span> Local Storage</span>
                    Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header
                    </p>
                    <p className="pt-5">
                    <span className="block font-bold mb-2 relative ml-3"> <span className="w-1 h-1 content-none bg-isDark500 absolute -left-2.5 top-2 rounded-full"></span> Session </span>
                    PayTyme uses "Sessions" to identify the areas of our website that you have visited. A Session is a small piece of data stored on your computer or mobile device by your web browser
                    </p>
                  </div>
                </div>
              </article>

            </div>
          </section>

          <Contact/>
          
        </div>
      </main>
      {/* End Main */}

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
