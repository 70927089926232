export const AIRTIME_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M42.2191 38.0513L39.8058 35.638C40.1387 35.3329 40.3956 34.9789 40.5764 34.5761C40.7562 34.1745 40.846 33.7517 40.846 33.3079C40.846 32.8641 40.7562 32.4413 40.5764 32.0397C40.3956 31.6369 40.1387 31.2829 39.8058 30.9778L42.2191 28.5645C42.8571 29.2025 43.3426 29.9304 43.6755 30.7481C44.0083 31.567 44.1748 32.4202 44.1748 33.3079C44.1748 34.1956 44.0083 35.0483 43.6755 35.866C43.3426 36.6849 42.8571 37.4133 42.2191 38.0513ZM46.2968 42.129L43.9667 39.7989C44.8266 38.939 45.4924 37.9542 45.9639 36.8447C46.4355 35.7351 46.6713 34.5562 46.6713 33.3079C46.6713 32.0596 46.4355 30.8807 45.9639 29.7712C45.4924 28.6616 44.8266 27.6768 43.9667 26.8169L46.2968 24.4868C47.4896 25.6519 48.405 26.9972 49.043 28.5229C49.681 30.0485 50 31.6436 50 33.3079C50 34.9723 49.681 36.5673 49.043 38.0929C48.405 39.6186 47.4896 40.9639 46.2968 42.129ZM23.3287 51.6158C22.4133 51.6158 21.63 51.2902 20.9786 50.6388C20.3262 49.9864 20 49.2025 20 48.2871V18.3287C20 17.4133 20.3262 16.6294 20.9786 15.977C21.63 15.3257 22.4133 15 23.3287 15H39.9723C40.8877 15 41.6716 15.3257 42.324 15.977C42.9753 16.6294 43.301 17.4133 43.301 18.3287V24.9861H39.9723V23.3218H23.3287V43.294H39.9723V41.6297H43.301V48.2871C43.301 49.2025 42.9753 49.9864 42.324 50.6388C41.6716 51.2902 40.8877 51.6158 39.9723 51.6158H23.3287ZM23.3287 46.6227V48.2871H39.9723V46.6227H23.3287ZM23.3287 19.9931H39.9723V18.3287H23.3287V19.9931Z" fill="white"/>
    </svg>
    
}

export const BETTING_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M40.5263 35.6764C41.2105 35.6764 41.7763 35.4527 42.2237 35.0053C42.6711 34.5579 42.8947 33.9921 42.8947 33.3079C42.8947 32.6237 42.6711 32.0579 42.2237 31.6106C41.7763 31.1632 41.2105 30.9395 40.5263 30.9395C39.8421 30.9395 39.2763 31.1632 38.8289 31.6106C38.3816 32.0579 38.1579 32.6237 38.1579 33.3079C38.1579 33.9921 38.3816 34.5579 38.8289 35.0053C39.2763 35.4527 39.8421 35.6764 40.5263 35.6764ZM23.1579 47.5185C22.2895 47.5185 21.5458 47.2095 20.9268 46.5916C20.3089 45.9727 20 45.229 20 44.3606V22.2553C20 21.3869 20.3089 20.6432 20.9268 20.0243C21.5458 19.4064 22.2895 19.0974 23.1579 19.0974H45.2632C46.1316 19.0974 46.8753 19.4064 47.4942 20.0243C48.1121 20.6432 48.4211 21.3869 48.4211 22.2553V26.2027H45.2632V22.2553H23.1579V44.3606H45.2632V40.4132H48.4211V44.3606C48.4211 45.229 48.1121 45.9727 47.4942 46.5916C46.8753 47.2095 46.1316 47.5185 45.2632 47.5185H23.1579ZM35.7895 41.2027C34.9211 41.2027 34.1779 40.8937 33.56 40.2758C32.9411 39.6569 32.6316 38.9132 32.6316 38.0448V28.5711C32.6316 27.7027 32.9411 26.959 33.56 26.34C34.1779 25.7221 34.9211 25.4132 35.7895 25.4132H46.8421C47.7105 25.4132 48.4542 25.7221 49.0732 26.34C49.6911 26.959 50 27.7027 50 28.5711V38.0448C50 38.9132 49.6911 39.6569 49.0732 40.2758C48.4542 40.8937 47.7105 41.2027 46.8421 41.2027H35.7895ZM46.8421 38.0448V28.5711H35.7895V38.0448H46.8421Z" fill="white"/>
    </svg>
    
    
}

export const DATA_ICON = () => {   
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
<path d="M23.3333 49.1413L50 22.3913V49.1413H23.3333ZM43.3333 45.8079H46.6667V30.4746L43.3333 33.8079V45.8079ZM26.4167 28.8079L24.25 26.6413C25.3056 25.5857 26.4722 24.8079 27.75 24.3079C29.0278 23.8079 30.3611 23.5579 31.75 23.5579C33.1389 23.5579 34.4722 23.8079 35.75 24.3079C37.0278 24.8079 38.1944 25.5857 39.25 26.6413L37.0833 28.8079C36.3333 28.0579 35.5 27.4952 34.5833 27.1196C33.6667 26.7452 32.7222 26.5579 31.75 26.5579C30.7778 26.5579 29.8333 26.7452 28.9167 27.1196C28 27.4952 27.1667 28.0579 26.4167 28.8079ZM22.1667 24.4746L20 22.3913C21.6389 20.7524 23.4722 19.5229 25.5 18.7029C27.5278 17.8841 29.6111 17.4746 31.75 17.4746C33.8889 17.4746 35.9861 17.8841 38.0417 18.7029C40.0972 19.5229 41.9444 20.7524 43.5833 22.3913L41.4167 24.4746C40.0833 23.1413 38.5761 22.1552 36.895 21.5163C35.215 20.8774 33.5 20.5579 31.75 20.5579C30 20.5579 28.2983 20.8774 26.645 21.5163C24.9928 22.1552 23.5 23.1413 22.1667 24.4746ZM31.75 34.1413L28.5417 30.9329C28.9861 30.4885 29.4861 30.1479 30.0417 29.9113C30.5972 29.6757 31.1667 29.5579 31.75 29.5579C32.3333 29.5579 32.8956 29.6691 33.4367 29.8913C33.9789 30.1135 34.4861 30.4607 34.9583 30.9329L31.75 34.1413Z" fill="white"/>
    </svg>  
}

export const EDUCATION_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M23 34.8079V37.8079H47V34.8079H23ZM23 18.3079H47C47.825 18.3079 48.5315 18.6014 49.1195 19.1884C49.7065 19.7764 50 20.4829 50 21.3079V37.8079C50 38.6329 49.7065 39.3394 49.1195 39.9274C48.5315 40.5144 47.825 40.8079 47 40.8079H41V48.3079L35 45.3079L29 48.3079V40.8079H23C22.175 40.8079 21.469 40.5144 20.882 39.9274C20.294 39.3394 20 38.6329 20 37.8079V21.3079C20 20.4829 20.294 19.7764 20.882 19.1884C21.469 18.6014 22.175 18.3079 23 18.3079ZM23 30.3079H47V21.3079H23V30.3079Z" fill="white"/>
    </svg>
    
    
}

export const PLANE_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M29.75 48.3079V46.0579L32.75 43.8079V35.5579L20 39.3079V36.3079L32.75 28.8079V20.5579C32.75 19.9329 32.969 19.4019 33.407 18.9649C33.844 18.5269 34.375 18.3079 35 18.3079C35.625 18.3079 36.156 18.5269 36.593 18.9649C37.031 19.4019 37.25 19.9329 37.25 20.5579V28.8079L50 36.3079V39.3079L37.25 35.5579V43.8079L40.25 46.0579V48.3079L35 46.8079L29.75 48.3079Z" fill="white"/>
    </svg>  
}

export const TAX_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M33.5 42.3079L39.5 36.3079L33.5 30.3079L31.4 32.4079L35.3 36.3079L31.4 40.2079L33.5 42.3079ZM23 47.5579C22.175 47.5579 21.469 47.2644 20.882 46.6774C20.294 46.0894 20 45.3829 20 44.5579V28.0579C20 27.2329 20.294 26.5269 20.882 25.9399C21.469 25.3519 22.175 25.0579 23 25.0579H29V22.0579C29 21.2329 29.294 20.5264 29.882 19.9384C30.469 19.3514 31.175 19.0579 32 19.0579H38C38.825 19.0579 39.5315 19.3514 40.1195 19.9384C40.7065 20.5264 41 21.2329 41 22.0579V25.0579H47C47.825 25.0579 48.5315 25.3519 49.1195 25.9399C49.7065 26.5269 50 27.2329 50 28.0579V44.5579C50 45.3829 49.7065 46.0894 49.1195 46.6774C48.5315 47.2644 47.825 47.5579 47 47.5579H23ZM23 44.5579H47V28.0579H23V44.5579ZM32 25.0579H38V22.0579H32V25.0579Z" fill="white"/>
    </svg>
    
    
}

export const TVSUB_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M24.5 39.3079H27.5C27.5 38.4829 27.206 37.7764 26.618 37.1884C26.031 36.6014 25.325 36.3079 24.5 36.3079V39.3079ZM29.825 39.3079H32C32 37.2329 31.2685 35.4639 29.8055 34.0009C28.3435 32.5389 26.575 31.8079 24.5 31.8079V33.9829C25.975 33.9829 27.2315 34.5019 28.2695 35.5399C29.3065 36.5769 29.825 37.8329 29.825 39.3079ZM34.325 39.3079H36.5C36.5 37.6579 36.1875 36.1014 35.5625 34.6384C34.9375 33.1764 34.0815 31.9014 32.9945 30.8134C31.9065 29.7264 30.6315 28.8704 29.1695 28.2454C27.7065 27.6204 26.15 27.3079 24.5 27.3079V29.4829C27.225 29.4829 29.544 30.4389 31.457 32.3509C33.369 34.2639 34.325 36.5829 34.325 39.3079ZM29 46.8079V43.8079H23C22.175 43.8079 21.469 43.5144 20.882 42.9274C20.294 42.3394 20 41.6329 20 40.8079V22.8079C20 21.9829 20.294 21.2764 20.882 20.6884C21.469 20.1014 22.175 19.8079 23 19.8079H47C47.825 19.8079 48.5315 20.1014 49.1195 20.6884C49.7065 21.2764 50 21.9829 50 22.8079V40.8079C50 41.6329 49.7065 42.3394 49.1195 42.9274C48.5315 43.5144 47.825 43.8079 47 43.8079H41V46.8079H29ZM23 40.8079H47V22.8079H23V40.8079Z" fill="white"/>
    </svg> 
}

export const UTILITY_ICON = () => {
    return <svg width="50" height="67" viewBox="0 0 70 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="70" height="66.6158" rx="6" fill="#9898F0"/>
    <path d="M45 38.3079V34.9746H48.3333C48.8056 34.9746 49.2011 35.1341 49.52 35.4529C49.84 35.7729 50 36.1691 50 36.6413C50 37.1135 49.84 37.5091 49.52 37.8279C49.2011 38.1479 48.8056 38.3079 48.3333 38.3079H45ZM45 44.9746V41.6413H48.3333C48.8056 41.6413 49.2011 41.8013 49.52 42.1213C49.84 42.4402 50 42.8357 50 43.3079C50 43.7802 49.84 44.1757 49.52 44.4946C49.2011 44.8146 48.8056 44.9746 48.3333 44.9746H45ZM38.3333 46.6413C37.4167 46.6413 36.6322 46.3152 35.98 45.6629C35.3267 45.0096 35 44.2246 35 43.3079H31.6667V36.6413H35C35 35.7246 35.3267 34.9396 35.98 34.2863C36.6322 33.6341 37.4167 33.3079 38.3333 33.3079H43.3333V46.6413H38.3333ZM26.6667 41.6413C24.8333 41.6413 23.2639 40.9885 21.9583 39.6829C20.6528 38.3774 20 36.8079 20 34.9746C20 33.1413 20.6528 31.5718 21.9583 30.2663C23.2639 28.9607 24.8333 28.3079 26.6667 28.3079H29.1667C29.8611 28.3079 30.4511 28.0646 30.9367 27.5779C31.4233 27.0924 31.6667 26.5024 31.6667 25.8079C31.6667 25.1135 31.4233 24.5235 30.9367 24.0379C30.4511 23.5513 29.8611 23.3079 29.1667 23.3079H23.3333C22.8611 23.3079 22.465 23.1485 22.145 22.8296C21.8261 22.5096 21.6667 22.1135 21.6667 21.6413C21.6667 21.1691 21.8261 20.7729 22.145 20.4529C22.465 20.1341 22.8611 19.9746 23.3333 19.9746H29.1667C30.7778 19.9746 32.1528 20.5441 33.2917 21.6829C34.4306 22.8218 35 24.1968 35 25.8079C35 27.4191 34.4306 28.7941 33.2917 29.9329C32.1528 31.0718 30.7778 31.6413 29.1667 31.6413H26.6667C25.75 31.6413 24.9656 31.9674 24.3133 32.6196C23.66 33.2729 23.3333 34.0579 23.3333 34.9746C23.3333 35.8913 23.66 36.6757 24.3133 37.3279C24.9656 37.9813 25.75 38.3079 26.6667 38.3079H30V41.6413H26.6667Z" fill="white"/>
    </svg> 
}

export const PADLOCK_ICON = () => {
    return <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 0.5C4.57174 0.5 3.6815 0.868749 3.02513 1.52513C2.36875 2.1815 2 3.07174 2 4V6C1.46957 6 0.960859 6.21071 0.585787 6.58579C0.210714 6.96086 0 7.46957 0 8V12.5C0 13.0304 0.210714 13.5391 0.585787 13.9142C0.960859 14.2893 1.46957 14.5 2 14.5H9C9.53043 14.5 10.0391 14.2893 10.4142 13.9142C10.7893 13.5391 11 13.0304 11 12.5V8C11 7.46957 10.7893 6.96086 10.4142 6.58579C10.0391 6.21071 9.53043 6 9 6V4C9 2.06667 7.43333 0.5 5.5 0.5ZM8 6V4C8 3.33696 7.73661 2.70107 7.26777 2.23223C6.79893 1.76339 6.16304 1.5 5.5 1.5C4.83696 1.5 4.20107 1.76339 3.73223 2.23223C3.26339 2.70107 3 3.33696 3 4V6H8Z" fill="#ADADF3"/>
    </svg>
    
}